import React from 'react'
import {useLocation} from 'react-router-dom'

/**
 * Helpers and types
 */
import {pure} from '@react/derive'
import {http} from '@helpers/common'
import string from '@helpers/string'
import {useState} from '@react/hooks'
import {UserInterface} from '@interface/common'
import {DefaultComponentProps} from '@interface/common'


/**
 * Assets
 */
import './accounts.scss'
import Spinner from '@assets/images/spinner.gif'


/**
 * Components
 */
import Search from '@components/search'
import Checkbox from '@components/checkbox'
import TableBottom from '@components/table/bottom'


type InviteType = {
  name: string
  email: string
}


/**
 * Accounts Screen
 */
export default pure(({user, store, modal}: DefaultComponentProps) => {
  const data = useLocation()

  const state = useState({
    limit: 5,
    next: null,
    invited: 1,
    current: 1,
    emails: [],
    selected: [],
    accounts: [],
    fetching: true,
    organization: data.state ? data.state : (user.organization ?? {}),
  })

  /**
   * Get accounts
   */
  React.useEffect(() => {
    getAccounts({limit: 5})
  }, [])

  /**
   * Update
   */
  React.useEffect(() => {
    var account = store.get('account')
    var confirmed = store.get('confirmed')
    
    /**
     * Delete incomplete accounts if confirmed
     */
    if(confirmed) {
      const ids = state.selected.map((v:any) => v.uid)

      switch(confirmed) {
        case 'delete-account':
          remove('accounts', ids)
          break
        case 'eliminate-account':
          remove('organizations/eliminate', ids)
          break
      }
    }

    /**
     * Update accounts
     */
    if(account) {
      state.set(({accounts}:any) => {
        if(!Array.isArray(account)) {
          account = [account]
        }
        return {
          accounts: account.concat(accounts)
        }
      })
      store.dispatch({account: null})
    }
  }, [store])
  

  function page(name: string, page: string|number, last?: string|number) {
    if(page) {
      getAccounts({
        last,
        [name]: page,
        limit: state.limit
      })
    }
  }


  /**
   * Remove
   */
  function remove(path:string, ids:string[]) {
    http.delete(`/${path}`, {
      query: {
        ids: ids.filter((v => v !== user.uid)),
      }
    })
    .then(({status, result}) => {
      if(status === 200 && result) {
        store.dispatch({
          confirmed: false
        })

        state.set(({accounts}:any) => {
          return {
            selected: [],
            accounts: accounts.filter((v: any) => !result.includes(v.uid))
          }
        })
      }
    })
    .catch(e => console.log(e))
  }

  /**
   * Is checked?
   */
  function isChecked() {
    if(state.selected.length === 0) {
      return false
    }
    return state.selected.length === state.accounts.length ? true : false
  }

  /**
   * Get accounts
   */
  function getAccounts(query = {}) {
    http.get('/accounts', {
      query: {
        ...query,
        oid: state.organization.oid
      }
    })
    .then(({status, result}) => {
      if(status === 200) {
        state.set({
          fetching: false,
          total: result.total,
          accounts: result.accounts ?? [],
        })
      }
    })
    .catch(e => console.log(e))
  }

  /**
   * Send invitation to incomplete accounts
   */
  function sendInvitation(accounts:InviteType[]) {
    modal.open({
      name: 'sending',
      data: {
        title: 'Sending Invitation',
        message: `Please don't reload the page.`
      }
    })
    http.post('/accounts/invite', {
      data: {
        accounts,
      }
    })
    .then(({status, result}) => {
      if(status === 200 && result) {

        const map = (item:UserInterface) => {
          item = {...item}
          if(result.includes(item.email)) {
            item.status = 'invited'
            return item
          }
          return item
        }
        setTimeout(() => {
          state.set(({accounts}:any) => {
            return {
              accounts: accounts.map(map),
              selected: []
            }
          })
          modal.close()
        }, 2000)
      }
    })
    .catch(e => console.log(e))
  }


  return (
    <div id="accounts">
      <header>
        <div className="left">
          <h3 className="margin-0">Accounts</h3>
        </div>
        <div className="right inline">
          <div className="search">
            <label>Search</label>
            <Search
              type={'account'}
              args={{
                oid: state.organization.oid
              }}
              onChange={(data: object[]) => {
                state.set({
                  accounts: data
                })
              }}
            />
          </div>
          <div className="filter">
            <label>Status</label>
            <select
              onChange={(e) => {
                getAccounts({status: e.target.value})
              }}>
              <option value="">All</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
              <option value="invited">Invited</option>
              <option value="incomplete">Incomplete</option>
            </select>
          </div>
          <div className="add-button">
            <span
              className="button-3"
              onClick={() => {
                modal.open({name: 'addAccount', data: data.state})
              }}>
              <span className="icon-plus"></span>
              <span className="text">Add or Import Accounts</span>
            </span>
          </div>
        </div>
      </header>
      
      <div className="table">
        <div className="meta inline">
          <div className="org inline">
            <span className="label">
              <i className="icon-building"/>
              <strong>Organization:</strong>
            </span>
            <span>{state.organization.name}</span>
          </div>

          {state.selected.length > 0 && (
            <div className="buttons inline">
              <div
                className="button-3"
                onClick={() => {
                  sendInvitation(state.selected)
                }}>
                <span className="icon">
                  <i className="icon-plane-slope"></i>
                </span>
                <span className="text">Send Invitation ({state.selected.length})</span>
              </div>

              <div
                className="button-1"
                onClick={() => {
                  modal.open({
                    name: 'alert',
                    data: {
                      key: 'delete-account',
                      title: 'Confirm Deletion',
                      button: 'Remove',
                      message: `These accounts will be deleted in the database. \nAre you sure you want to remove these accounts (${state.selected.length})`
                    }
                  })
                }}>
                <span className="icon">
                  <i className="icon-bin"></i>
                </span>
                <span className="text">Remove Incomplete ({state.selected.length})</span>
              </div>

              <div
                className="button-1"
                onClick={() => {
                  modal.open({
                    name: 'alert',
                    data: {
                      key: 'eliminate-account',
                      title: 'Confirm Elimination',
                      button: 'Eliminate',
                      message: `These accounts will be no longer part of your organization. \nAre you sure you want to eliminate these accounts (${state.selected.length})`
                    }
                  })
                }}>
                <span className="icon">
                  <i className="icon-bin"></i>
                </span>
                <span className="text">Remove from Organization ({state.selected.length})</span>
              </div>
            </div>
          )}
        </div>

        <table className="list">
          <thead>
            <tr>
              <th>
                <Checkbox
                  space={30}
                  onClick={(checked: boolean) => {
                    state.set({
                      selected: state.accounts.reduce(
                        (items: any[], item: any) => {
                          if(checked) {
                            items.push({
                              uid: item.uid,
                              email: item.email,
                              status: item.status,
                              name: item.firstname,
                            })
                          }
                          else {
                            return items.filter((v: any) => v.uid !== item.uid)
                          }
                          return items
                        },
                      [])
                    })
                  }}
                  checked={isChecked()}
                />
              </th>
              <th>Name</th>
              <th>Email</th>
              <th>Status</th>
              <th>Sub Accounts</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {state.fetching ? (
              <tr>
                <td colSpan={5} style={{textAlign: 'center'}}>
                  <img alt={'Spinner'} src={Spinner} style={{width: 32, padding: 20}}/>
                </td>
              </tr>
            ):(
              <>
                {state.accounts.length > 0 ? (
                  <>
                    {state.accounts.map((item: UserInterface, key: number) => {
                      return (
                        <tr key={key}>
                          <td style={{width: 20}}>
                            <Checkbox
                              space={30}
                              onClick={(checked: boolean) => {

                                state.set(({selected}: any) => {
                                  if(checked) {
                                    selected.push({
                                      uid: item.uid,
                                      email: item.email,
                                      status: item.status,
                                      name: item.firstname
                                    })
                                  }
                                  else {
                                    selected = selected.filter((v: any) => v.uid !== item.uid)
                                  }
                                  return {selected}
                                })
                              }}
                              checked={(
                                state.selected.filter((v: any) => v.uid === item.uid).length > 0
                              )}
                            />  
                          </td>
                          <td>
                            <span>{item.firstname} {item.lastname}</span>
                            {user.uid === item.uid && (
                              <span className="you">You</span>
                            )}
                          </td>
                          <td>{item.email}</td>
                          <td>{string.toUCFirst(item.status)}</td>
                          <td>{item.subs?.length}</td>
                          <td style={{width: 100}}>
                            <div className="flex-end">
                              {item.status === 'invited' && (
                                <span className="button-5 opacity-05">Invited</span>
                              )}
                              {item.status === 'incomplete' && (
                                <span
                                  className="button-5"
                                  onClick={() => {
                                    sendInvitation([{
                                      email: item.email,
                                      name: item.firstname
                                    }])
                                  }}>
                                  <span>Send Invitation</span>
                                </span>
                              )}
                              <span
                                className="button-1"
                                onClick={() => {
                                  modal.open({data: item, name: 'accountDetail'})
                                }}>
                                <span>Detail</span>
                              </span>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </>
                ):(
                  <tr>
                    <td colSpan={6} style={{textAlign: 'center'}}>
                      <span>No Results</span>
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      <TableBottom
        entries={{
          onChange(e) {
            getAccounts({limit: e.target.value})
          }
        }}
        pagination={{
          limit: state.limit,
          total: state.total,
          onClick(selected) {
            page('page', selected, state.accounts.at(-1)?.created)
          },
          onPrev() {
            page('prev', state.accounts[0]?.created)
          },
          onNext() {
            page('next', state.accounts.at(-1)?.created)
          },
        }}
      />
    </div>
  )
})