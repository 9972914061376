import React from 'react'
import {
  RouterProvider,
  createBrowserRouter,
}
from 'react-router-dom'

/**
 * Components
 */
import Modals from '@components/modals'
import Container from '@layout/container'

/**
 * Pages
 */
import Error from '@pages/error'
import Login from '@pages/auth/login'
import Reset from '@pages/auth/reset'
import Forgot from '@pages/auth/forgot'
import Register from '@pages/auth/register'
import Tickets from '@pages/tickets'
import Accounts from '@pages/accounts'
import Dashboard from '@pages/dashboard'
import Blocked from '@pages/posts/blocked'
import Reported from '@pages/posts/reported'
import Organizations from '@pages/organizations'
import GeneralSettings from '@pages/settings/general'
import TemplateSettings from '@pages/settings/template'
import InvitationSettings from '@pages/settings/invitation'

/**
 * Styles
 */
import '@assets/sass/global.scss'
import '@assets/sass/layout.scss'
import '@assets/sass/common.scss'


/**
 * Routes
 */
export default function Router() {
  return (
    <React.Fragment>
      <RouterProvider
        router={createBrowserRouter([
          {
            path: '/',
            element: <Login/>,
            errorElement: <Error/>,
          },
          {
            path: '/organization/register',
            element: <Register/>
          },
          {
            path: '/auth',
            children: [
              {
                path: 'reset',
                element: <Reset/>,
              },
              {
                path: 'forgot',
                element: <Forgot/>
              },
            ]
          },
          {
            path: '/office',
            element: <Container/>,
            children: [
              {
                path: 'dashboard',
                element: <Dashboard/>
              },
              {
                path: 'accounts',
                element: <Accounts/>
              },
              {
                path: 'posts',
                children: [
                  {
                    path: 'blocked',
                    element: <Blocked/>
                  },
                  {
                    path: 'reported',
                    element: <Reported/>
                  }
                ]
              },
              {
                path: 'organizations',
                element: <Organizations/>
              },
              {
                path: 'support/tickets',
                element: <Tickets/>
              },
              {
                path: 'settings',
                children: [
                  {
                    path: 'general',
                    element: <GeneralSettings/>
                  },
                  {
                    path: 'template',
                    element: <TemplateSettings/>
                  },
                  {
                    path: 'invitation',
                    element: <InvitationSettings/>
                  }
                ]
              },
            ]
          },
        ]
      )}/>
      <Modals/>
    </React.Fragment>
  )
}