const auth = [
  'Moderator',
  'Administrator',
  'Organization Administrator'
]

/**
 * Export config
 */
const config = {
  /**
   * All roles
   */
  roles: [
    'Member',
    ...auth
  ],

  /**
   * Reset time
   */
  resetTimeout: 600000,

  /**
   * Authorized users
   */
  authorized: auth,

  /**
   * Local storage
   */
  storage: {
    name: 'persist:root'
  },

  /**
   * Main navigation
   */
  navigation: [
    // {
    //   name: 'Dashboard',
    //   icon: 'icon-chart',
    //   auth: ['Administrator', 'Organization Administrator']
    // },
    {
      name: 'Accounts',
      icon: 'icon-user',
      auth: ['Administrator', 'Organization Administrator']
    },
    {
      name: 'Posts',
      icon: 'icon-document',
      group:[
        {name: 'Blocked', auth},
        {name: 'Reported', auth},
      ],
      auth: ['Administrator'],
    },
    {
      name: 'Organizations',
      icon: 'icon-building',
      auth: ['Administrator']
    },
    {
      name: 'Support',
      icon: 'icon-headphone',
      group: [
        {name: 'Tickets'},
      ],
      auth: ['Administrator']
    },
    {
      name: 'Settings',
      icon: 'icon-cog',
      group: [
        {name: 'General'},
        {name: 'Invitation'},
        {name: 'Roles'},
      ],
      auth: ['Administrator']
    },
  ],
  industries: [
    'Retail',
    'Hotel and Lodging',
    'Artificial Intelligence',
    'Education',
    'Entertainment and Leisure',
    'Healthcare',
    'Telecommunications',
    'Utilities',
    'Finance and Insurance',
    'Information Technology',
    'Transportation and Logistics',
    'Agriculture, Forestry and Fishing',
    'Construction',
    'Media and Entertainment',
    'Legal',
    'Government and Public Administration',
    'Real Estate and Property Management',
    'Manufacturing',
    'Wholesale and Distribution',
    'E-commerce and Online Retail'
  ]
}
export default config