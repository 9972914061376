import React from 'react'


/**
 * Derive and interface
 */
import {pure} from '@react/derive'
import {DefaultComponentProps} from '@interface/common'

/**
 * Modals
 */
import alert from '@components/modals/alert'
import review from '@components/modals/review'
import sending from '@components/modals/sending'
import addOrg from '@components/modals/organizations/add'
import addAccount from '@components/modals/accounts/add'
import accountDetail from '@components/modals/accounts/detail'


type ModalType = {
  [key: string]: React.FunctionComponent<any>
}

/**
 * List of Modals
 */
const List: ModalType = {
  alert,
  addOrg,
  review,
  sending,
  addAccount,
  accountDetail,
}

/**
 * Export a certain modal
 */
export default pure(({store}: DefaultComponentProps) => {
  var modal = store.get('modal')

  if(typeof modal === 'string') {
    modal = {
      name: modal
    }
  }
  
  if(modal) {
    modal = Object.assign({close: true}, modal)

    var Content = List[modal.name]
    if(Content) {
      return (
        <div
          className="modal"
          onClick={(e) => {
            store.dispatch({modal: null})
          }}>
          <Content data={modal.data}/>
        </div>
      )
    }
  }
  return null
})