import React from 'react'
import {useNavigate} from 'react-router-dom'

/**
 * Helpers
 */
import {pure} from '@react/derive'
import {http} from '@helpers/common'
import string from '@helpers/string'
import object from '@helpers/object'
import {useState} from '@react/hooks'

/**
 * Components
 */
import Button from '@components/button'
import Checkbox from '@components/checkbox'

/**
 * Assets
 */
import './auth.scss'
import Logo from '@assets/images/logo-orange.png'


/**
 * Login Screen
 */
export default pure(() => {
  const state = useState({
    error: null,
    submit: false,
    user: {
      email: null,
      location: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    },
    org: {
      name: null,
      email: null,
      type: 'Education',
      role: 'Organization Administrator'
    },
    preload: false,
    isExistingAccount: false,
  })
  const navigate = useNavigate()
  


  /**
   * Populate data from query
   */
  React.useEffect(() => {
    const query = string.parse(location.search)
    if(query.data) {
      const decode = string.decode(query.data)

      if(decode) {
        const parsed = JSON.parse(decode)

        state.set(({org, user}:any) => {
          return {
            user: {...user, email: parsed.email},
            org: {...org, email: parsed.email, name: parsed.name}
          }
        })
      }
    }
  }, [])


  /**
   * Input field handler 
   */
  function onChange(key: string, data: any) {
    state.set((_state:any) => {
      return {
        [key]: {..._state[key], ...data}
      }
    })
  }

  /**
   * Register Organization
   */
  function register(e: any) {
    e.preventDefault()

    const required = [
      'name',
      'email',
      'address',
      'contact'
    ]

    if(!object.has(required, state.org) && !object.has('email', state.user)) {
      return state.set({
        error: 'Please fill required fields. (*)'
      })
    }

    if(!state.isExistingAccount && state.user.password !== state.confirmPassword) {
      return state.set({
        error: 'Password and confirmation password should match.'
      })
    }


    http.post('/organizations', {
      data: {
        ref: 'register',
        org: state.org,
        user: state.user,
        isExistingAccount: state.isExistingAccount
      }
    })
    .then(({status}) => {
      if(status === 201) {
        navigate('/', {
          state: {
            org: state.org,
            user: state.user,
          }
        })
      }
    })
    .catch(e => console.log(e))
  }


  return (
    <div id="auth">
      <header id="header">
        <img id="logo" src={Logo} alt="logo" />
        <span>|  A safe social media platform</span>
      </header>
      <div className="box">
        <h1>Register your organization to bhapi</h1>
        <form id="form" onSubmit={register}>
          <div className="group">
            <h3>Organization Information</h3>
          
            <div className="inline">
              <div className="field">
                <label>
                  <i className="icon-building"></i>
                  <span>Organization Name</span>
                </label>
                <input
                  required
                  type="text"
                  value={state.org.name}
                  onChange={(e) => onChange('org', {name: e.target.value})}/>
              </div>
              <div className="field">
                <label>
                  <i className="icon-email"></i>
                  <span>Email Address</span>
                </label>
                <input
                  required
                  type="email"
                  value={state.org.email}
                  onChange={(e) => onChange('org', {email: e.target.value})}/>
              </div>
            </div>
            <div className="inline">
              <div className="field">
                <label>
                  <i className="icon-pin"></i>
                  <span>Address</span>
                </label>
                <input type="text" onChange={(e) => onChange('org', {address: e.target.value})} required/>
              </div>
              <div className="field">
                <label>
                  <i className="icon-phone"></i>
                  <span>Contact Number</span>
                </label>
                <input type="text" onChange={(e) => onChange('org', {contact: e.target.value})} required/>
              </div>
            </div>
          </div>

          <div className="group">
            <h3>Administrator Account</h3>

            <div className="field">
              <Checkbox
                label={'Use my existing bhapi account email address as administrator.'}
                space={10}
                onClick={(checked: boolean) => {
                  state.set({
                    isExistingAccount: checked ? true : false
                  })
                }}
                checked={state.isExistingAccount}
              />
            </div>
            {state.isExistingAccount ? (
              <div className="inline">
                <div className="field">
                  <label>
                    <i className="icon-email"></i>
                    <span>Email Address</span>
                  </label>
                  <input type="email" onChange={(e) => onChange('user', {email: e.target.value})} required/>
                </div>
                <div className="field"></div>
              </div>
            ):(
              <React.Fragment>
                <div className="inline">
                  <div className="field">
                    <label>
                      <i className="icon-user"></i>
                      <span>First Name</span>
                    </label>
                    <input type="text" onChange={(e) => onChange('user', {firstname: e.target.value})} required/>
                  </div>
                  <div className="field">
                    <label>
                      <i className="icon-user"></i>
                      <span>Last Name</span>
                    </label>
                    <input type="text" onChange={(e) => onChange('user', {lastname: e.target.value})} required/>
                  </div>
                </div>
                <div className="inline">
                  <div className="field">
                    <label>
                      <i className="icon-email"></i>
                      <span>Email Address</span>
                    </label>
                    <input
                      required
                      type="email"
                      value={state.user.email}
                      onChange={(e) => onChange('user', {email: e.target.value})}/>
                  </div>
                  <div className="field">
                    <label>
                      <i className="icon-lock"></i>
                      <span>Birth Date</span>
                    </label>
                    <input type="text" onChange={(e) => onChange('user', {birthday: e.target.value})}/>
                  </div>
                </div>
                <div className="inline">
                  <div className="field">
                    <label>
                      <i className="icon-lock"></i>
                      <span>Password</span>
                    </label>
                    <input type="password" onChange={(e) => onChange('user', {password: e.target.value})} required/>
                  </div>
                  <div className="field">
                    <label>
                      <i className="icon-email"></i>
                      <span>Confirm Password</span>
                    </label>
                    <input type="password" onChange={(e) => state.set({confirmPassword: e.target.value})} required/>
                  </div>
                </div>
                <p><em>Note: You can use the same account for your organization admin and bhapi social media app.</em></p>
              </React.Fragment>
            )}
          </div>

          {state.error && (
            <p className="error">{state.error}</p>
          )}
          
          <div className="action">
            <Button
              style={{
                width: '10%',
                alignSelf: 'center'
              }}
              label={'Submit'}
              className={'button-3'}
            />
          </div>
        </form>
      </div>
    </div>
  )
})