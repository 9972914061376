import React from 'react'
import {useLocation} from 'react-router-dom'

/**
 * Helpers and types
 */
import {pure} from '@react/derive'
import {http} from '@helpers/common'
import string from '@helpers/string'
import {useState} from '@react/hooks'
import {UserInterface} from '@interface/common'
import {DefaultComponentProps} from '@interface/common'


/**
 * Assets
 */
import Spinner from '@assets/images/spinner.gif'


/**
 * Components
 */
import Search from '@components/search'


/**
 * Organizations Screen
 */
export default pure(({user, store, modal}: DefaultComponentProps) => {
  const state = useState({})

  return (
    <div id="dashboard">
      <header>
        <div className="left">
          <h3 className="margin-0">Dashboard</h3>
        </div>
      </header>
    </div>
  )
})