import React from 'react'
/**
 * Helpers and types
 */
import {pure} from '@react/derive'
import string from '@helpers/string'
import {useState} from '@react/hooks'
import {datetime} from '@helpers/common'
import {TicketType, DefaultComponentProps} from '@interface/common'



type TicketProps = DefaultComponentProps & {
  status: string
  ticket: TicketType
  open(status: string): void
}

/**
 * Ticket Screen
 */
export default pure(({user, ticket, status, open}: TicketProps) => {
  const state = useState({
    message: '',
    history: [],
    socket: null,
    ticket,
    ticketStatus: ticket.status,
  })

  const date = datetime(ticket.created)
  const assignee = {
    uid: user.uid,
    role: user.organization.role,
    name: `${user.firstname} ${user.lastname}`
  }

  const textRef = React.useRef<HTMLDivElement>()
  const historyRef = React.useRef<HTMLDivElement>()


  /**
   * Connect to websocket server
   */
  function connect() {
    const url = user.links.support.concat(`&tid=${ticket.tid}`)
    /**
     * Check if websocket is supported
     */
    if(!window.WebSocket) {
      alert('WebSocket is not supported in your current browser. Please upgrade to latest version.');
    }
    return new WebSocket(url)
  }


  /**
   * Get tickets
   */
  React.useEffect(() => {
    const socket = connect()
    /**
     * 
     */
    socket.onopen = function() {
      console.log('Connected')
    }
    socket.onerror = console.log

    /**
     * 
     */
    socket.onmessage = function(message:any) {
      const {type, data} = JSON.parse(message.data)

      if(type === 'ticket') {
        state.set({ticket: data})
      }

      if(type === 'history') {
        state.set({history: data.messages})
      }

      if(type === 'chat') {
        state.set(({history}:any) => {
          history.push(data)

          if(textRef.current) {
            textRef.current.innerHTML = ''
          }
          if(historyRef.current) {
            if(historyRef.current.lastElementChild) {
              historyRef.current.lastElementChild.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'})
            }
          }
          
          return {history}
        })
      }
    }
    state.set({socket})
  }, [ticket])


  return (
    <div id="chatbox">
      <div
        className="head"
        onClick={(e) => {
          e.stopPropagation()
          if(status === 'hide') {
            open('open')
          }
        }}>
        <div>
          <strong>{ticket.user?.name}</strong>
        </div>
        <div className="controls">
          <span
            onClick={() => {
              if(status === 'open') {
                open('hide')
              }
            }}>
            {status === 'open' ? (
              <i className="icon-minus"/>
            ):(
              <i className="icon-stop"/>
            )}
          </span>
          <span
            onClick={(e) => {
              e.stopPropagation()
              open('close')
            }}>
            <i className="icon-close"/>
          </span>
        </div>
      </div>

      {status === 'open' && (
        <div  className="chat">

          <div className="history">
            <div
              ref={(ref) => {
                if(ref) {
                  historyRef.current = ref
                }
              }}
              className="messages">
              {state.history.map((item:any, index:number) => {
                return (
                  <div
                    key={index}
                    style={{
                      textAlign: user.uid === item.from ? 'right' : 'left',
                    }}>
                    <span
                      className="bubble"
                      style={{
                        color: user.uid === item.from ? '#fff' : '#000',
                        backgroundColor: user.uid === item.from ? '#713edd' : '#f1f1f1'
                      }}>
                      {item.message}
                    </span>
                  </div>
                )
              })}
            </div>
            
            {state.ticket.status === 'open' ? (
              <div className="inputbox">
                <div className="input">
                  <div
                    className="text"
                    contentEditable
                    onFocus={() => {
                      state.set({focus: true})
                    }}
                    onBlur={() => {
                      state.set({focus: false})
                    }}
                    ref={(ref) => {
                      if(ref) {
                        textRef.current = ref
                      }
                    }}
                  />

                  {!state.focus && (
                    <span>Type your message here.</span>
                  )}
                </div>

                <button
                  className="button-1"
                  onClick={(e) => {
                    e.preventDefault()

                    if(textRef.current) {
                      const message = {
                        type: 'chat',
                        data: {
                          tid: ticket.tid,
                          to: ticket.tid,
                          from: user.uid,
                          message: textRef.current?.innerText
                        }
                      }
                      state.socket.send(JSON.stringify(message))
                    }
                  }}>
                  <i className="icon-plane-slope"/>
                  <span>Send</span>
                </button>

              </div>
            ):(
              <div
                className="inputbox"
                style={{
                  display: 'block',
                  textAlign: 'center'
                }}>
                <button
                  className="button-3 open-ticket"
                  onClick={() => {
                    state.socket.send(
                      JSON.stringify({
                        type: 'ticket',
                        data: {status: 'open', assignee}
                      })
                    )
                  }}>
                  Open and Start Conversation
                </button>
              </div>
            )}
          </div>


          <div className="user-info">
            <div className="items">

              <div className="detail">
                <label>User</label>
                <div>{ticket.user.name}</div>
              </div>

              <div className="detail">
                <label>Issue</label>
                <div>{ticket.issue}</div>
              </div>

              <div className="detail">
                <label>Status</label>
                <div>{string.toUCFirst(state.ticket.status)}</div>
              </div>

              <div className="detail">
                <label>Assignee</label>
                <div>{state.ticket.assignee?.name ?? '--'}</div>
              </div>

              <div className="detail">
                <label>Created</label>
                <div>{date.getFullDate()}</div>
              </div>
            </div>

            <div className="action">
              <button
                className="button-1 close-ticket"
                onClick={() => {
                  state.socket.send(
                    JSON.stringify({
                      type: 'ticket',
                      data: {status: 'closed', assignee}
                    })
                  )
                }}>
                Close Ticket
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
})